// AWS Bundle Class
(function(global) {
    'use strict';

    // Check for required dependencies
    if (!global.jQuery) {
        throw new Error('jQuery not loaded');
    }

    if (!global.AWS) {
        throw new Error('AWS SDK not loaded');
    }

    if (!global.AmazonCognitoIdentity) {
        throw new Error('Amazon Cognito Identity SDK not loaded');
    }

    class AWSBundle {
        constructor() {
            if (!window.CognitoAuth) {
                throw new Error('CognitoAuth class not loaded');
            }
            this.auth = new window.CognitoAuth();
        }

        // Expose auth methods directly
        async login(username, password) {
            return this.auth.login(username, password);
        }

        async logout() {
            return this.auth.logout();
        }

        async isAuthenticated() {
            return this.auth.isAuthenticated();
        }

        async getCurrentUser() {
            return this.auth.getCurrentUser();
        }

        async getUserAttributes() {
            return this.auth.getUserAttributes();
        }

        async getSession() {
            return this.auth.getSession();
        }
    }

    // Initialize only if not already initialized
    if (!global.awsBundle) {
        try {
            global.awsBundle = new AWSBundle();
            console.log('AWS Bundle initialized successfully');
        } catch (error) {
            console.error('Failed to initialize AWS Bundle:', error);
            throw error;
        }
    }

    // CommonJS and AMD support
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = global.awsBundle;
    } else if (typeof define === 'function' && define.amd) {
        define([], function() { return global.awsBundle; });
    }

})(typeof window !== 'undefined' ? window : this);
